<template functional>
    <vm-back-top :right="10" :bottom="10" :timing="'ease'" :duration="1000">
        <div class="flex justify-center items-center h-12 w-12 rounded-full bg-green-400 text-white shadow-lg transition hover:bg-green-500">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z" fill="currentColor" />
            </svg>
        </div>
    </vm-back-top>
</template>
<script>
import Vue from 'vue';
import VmBackTop from 'vue-multiple-back-top';

Vue.component(VmBackTop.name, VmBackTop);
export default {};
</script>
<style scoped>
.vm-back-top {
    display: inherit;
    z-index: 1999;
}
</style>
